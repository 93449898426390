<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trader Funds</h4>
      </el-col>
     

      <!-- <el-col :xs="24" :sm="129" :md="16" :lg="16" :xl="16" v-if="getRoleSlug == 'admin'">
        <div class="btn-wrapper"  >
          <el-button  type="primary"  :loading="isExcelReady" @click="handleExportExcel()">Export to Excel</el-button>
        </div>
      </el-col>

      <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2" v-if="getRoleSlug == 'admin'">
        <div class="btn-wrapper"  >
          <el-button  type="primary" @click="handleCreate()">Add</el-button>
        </div>
      </el-col> -->
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
          <div class="btn-wrapper"  >
            <el-button  type="primary" :loading="isExcelReady" @click="handleExportExcel()">Export to Excel</el-button>
          </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="trading-client">
          <el-form
            :model="formData"
            ref="refForm"
            label-position="top"
            :status-icon="true"
          >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.userId"
                    label="User Name"
                    prop="userId"
                  >
                    <el-input v-model="listQuery.userId" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.amount"
                    label="Amount"
                    prop="amount"
                  >
                    <el-input v-model="listQuery.amount" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                  <div class="search-wrapper">
                    <el-form-item
                      :error="formErrors.date_range"
                      label="Daterange"
                      prop="Date Range"
                      :label-width="formLabelWidth"
                    >
                    <el-date-picker
                      v-model="listQuery.date_range"
                      type="daterange"
                      range-separator="To"
                      start-placeholder="Start date"
                      value-format ="yyyy-MM-dd"
                      end-placeholder="End date">
                    </el-date-picker>
                    </el-form-item>
                  </div>
                </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item label="" style="line-height: 40px;">
                    &nbsp;
                    </el-form-item>
                    <div class="broker-search-wrapper">
                    <el-button type="primary" @click="handleSearch()"
                      >Search</el-button
                    >
                    <el-button type="primary" @click="handleReset()"
                      >Reset</el-button
                    >
                    </div>
                  
                </div>

                
              </el-col>
            
            </el-row>
          </el-form>
        </div>
      </el-col>

    </el-row>
    </div>

    

    
    <Table
      tableKey="traderFunds"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableActionViewVisibility="false"
      :tableActionShowVisibility="false"
      :tableActionEditVisibility="false"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
      @pagination="handlePagination()"
      @view="handleViewDetails($event)"
    />
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table1.vue";
import AddEditDialog from "@/views/trader-funds/components/AddEditDialog.vue";
import { fetchList, store, fundExport } from "@/api/trader-fund";
import getRoleSlug from '@/store/app.js'
// import { _ } from "vue-underscore";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";



export default {
  name: "ListTraderFunds",
  components: {
    Table,
    AddEditDialog,
  },
  data() {
    return {
      dialogVisible: false,
      dialogType: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      formData: {},
      formErrors: [],
      config: [
        {
          label: "Id",
          prop: "id",
          width: "",
          className: "blueFont",
        },
        {
          label: "Username",
          prop: "username",
          width: "",
          className: "blueFont",
        },
        {
          label: "Name",
          prop: "name",
          width: "",
          className: "blueFont",
        },
        {
          label: "Type",
          prop: "type",
          width: "",
          className: "blueFont",
        },
        {
          label: "Amount",
          prop: "amount",
          width: "",
          className: "blueFont",
        },
        {
          label: "Notes",
          prop: "notes",
          width: "",
          className: "blueFont",
        },
        {
          label: "Added By",
          prop: "add_by",
          width: "",
          className: "blueFont",
        },
        {
          label: "CreatedAt",
          prop: "createdAt",
          width: "160",
          className: "blueFont",
        },
      ],
      listAdmins: {},
      listData: {
        data: {
          item: [],
        },
      },
      tablePagination: {},
      listQuery: {
        type: "customer",
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getList();
    this.getAdminList();
  },
  methods: {
    handlePagination() {
      this.getList();
    },
    handleReset() {
      this.listQuery = {
        type: "customer",
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        date_from:"",
        date_to:""
      },
      this.getList();
    },
    handleExportExcel() {
      this.isExcelReady = true
      fundExport(this.listQuery).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
        this.isExcelReady = false
      })
      .catch((err) => {
        console.log(err);
      });
    },
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
     handleViewDetails(data) {
       console.log(data)
      if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push("/super-admin/trader-funds/" +data.id );
      } else {
        return this.$router.push("/" + getRoleSlug.state.roleSlug + "/trader-funds/" +data.id);
      }
      // return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trader-funds/" + data.id);
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
    //  handleSearch() {
    //   this.listData.data.item = this.listDataTemp;
    //   let s = this.search;
    //   s = s.toLowerCase();
    //   this.listData.data.item = _.filter(this.listData.data.item, function (d) {
    //     return d["name"] && d["name"].toLowerCase().includes(s);
    //   });
    // },
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      if(this.listQuery.date_range){
        this.listQuery.date_from = this.listQuery.date_range[0]
        this.listQuery.date_to = this.listQuery.date_range[1]
      }
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getList() {
      this.loading = true;
      console.log(this.listQuery);
      fetchList(this.listQuery).then((response) => {
        this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
        console.log(this.listAdmins)
      });
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
      store(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
